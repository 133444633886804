module.exports = [{
      plugin: require('C:/wamp64/www/github-react-theme/bulma/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXXXX-X","anonymize":true},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/bulma/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/github-react-theme/bulma/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
